<template>
  <c-box v-if="data?.comparation?.length > 0 && data?.facilities?.length > 0">
    <c-flex
      :min-h="['41px', '70px']"
      align-items="flex-end"
    >
      <c-flex
        justify-content="center"
        align-items="center"
        width="100%"
        :height="['41px', '70px']"
        :background-color="tabActive === 0 ? 'primary.400' : '#C7F9E3'"
        border-top-left-radius="8px"
        border-top-right-radius="8px"
        class="tab-0"
        cursor="pointer"
        user-select="none"
        :_hover="{
          filter: 'brightness(0.95)'
        }"
        @click="changeTab(0)"
      >
        <c-text
          :color="tabActive === 0 ? 'white' : 'primary.400'"
          size-mobile="14px-3"
          size-desktop="18px"
          width="100%"
          text-align="center"
          font-weight="500"
          :font-size="['14px', '18px']"
        >
          Paket 30 hari 
        </c-text>
      </c-flex>
      <c-flex
        justify-content="center"
        align-items="center"
        width="100%"
        :height="['30px', '60px']"
        :background-color="tabActive === 1 ? 'primary.400' : '#C7F9E3'"
        border-top-left-radius="8px"
        border-top-right-radius="8px"
        class="tab-1"
        cursor="pointer"
        user-select="none"
        :_hover="{
          filter: 'brightness(0.95)'
        }"
        @click="changeTab(1)"
      >
        <c-text
          :color="tabActive === 1 ? 'white' : 'primary.400'"
          size-mobile="14px-3"
          size-desktop="18px"
          width="100%"
          text-align="center"
          font-weight="500"
          :font-size="['14px', '18px']"
        >
          Paket 90 hari
        </c-text>
      </c-flex>
    </c-flex>
    <c-box
      pos="relative"
      :display="['none', 'table']"
      as="table"
      width="100%"
      class="table-consultation-2"
      z-index="3"
    >
      <c-box
        as="thead"
        color="#FFF"
        background-color="#00A68C"
        font-weight="700"
      >
        <c-box
          v-chakra="{
            '>:first-child': {
              borderLeft: '1px solid #005A64',
            },
            '>:last-child': {
              borderRight: '1px solid #005A64',
            },
            '>:not(:last-child):not(:first-child)': {
              borderRight: '1px solid #005A64',
              borderLeft: '1px solid #005A64',
            },
          }"
          as="tr"
        >
          <c-box
            as="th"
            text-align="center"
            font-size="24px"
            py="1rem"
          >
            Fasilitas Layanan
          </c-box>
          <c-box
            v-for="item in data?.comparation?.[tabActive]?.productServices || []"
            :key="item.id"
            as="th"
            flex-direction="column"
            justify="center"
            py="1rem"
          >
            <c-text
              font-size="20px"
              margin-bottom="8px"
            >
              {{ item?.programsService }}
            </c-text>
            <c-text
              font-size="32px"
            >
              <c-text
                as="span"
                font-size="16px"
                font-weight="400"
              >
                Mulai dari Rp
              </c-text>
              {{ formatCurrency(item?.price, 2).replace('Rp. ', '') }}
            </c-text>
            <c-text
              as="span"
              font-size="16px"
              font-weight="400"
            >
              Per Bulan ({{ formatCurrency(Math.floor(item?.price / item?.duration), 2) }}/hari)
            </c-text>
          </c-box>
        </c-box>
      </c-box>
      <c-box
        v-chakra="{
          '> tr': {
            borderLeft: '1px solid #C4C4C4',
            borderRight: '1px solid #C4C4C4',
          },
        }"
        as="tbody"
        background-color="#FFF"
        color="#000"
        :font-size="['12px', '16px']"
      >
        <c-box
          v-for="(facility, i) in data?.facilities || []"
          :key="`tr-${i}`"
          v-chakra="{
            '>:not(:last-child):not(:first-child)': {
              borderRight: '1px solid #C4C4C4',
              borderLeft: '1px solid #C4C4C4',
            },
          }"
          as="tr"
        >
          <c-box
            v-for="k in ((data?.comparation?.[tabActive]?.productServices?.length || 0) + 1)"
            :key="`td-${i}-${k}`"
            as="td"
            px="1rem"
            :text-align="k === 1 ? 'left' : 'center'"
          >
            <c-box
              v-if="k === 1"
              w="100%"
              :pt="i === 0 ? '2rem' : '1rem'"
              pb="1rem"
              :px="k === 1 ? '12px' : '0'"
              border-bottom="1px solid #C4C4C4"
            >
              {{ facility?.facility }}
            </c-box>
            <c-box
              v-else
              w="100%"
              :pt="i === 0 ? '2rem' : '1rem'"
              pb="1rem"
              border-bottom="1px solid #C4C4C4"
              display="flex"
              justify-content="center"
            >
              <inline-svg
                v-if="isCheckList(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices[k-2]?.productFacilitiesComparation))"
                :src="checkIcon(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices[k-2]?.productFacilitiesComparation)) ? require('@/assets/icons/icon-circle-check.svg') : require('@/assets/icons/icon-circle-close.svg')"
                class="icon-list"
                :fill="checkIcon(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices[k-2]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
              />
              <c-box
                v-else
              >
                {{ getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices[k-2]?.productFacilitiesComparation) }}
              </c-box>
            </c-box>
          </c-box>
        </c-box>
        <c-box
          v-chakra="{
            '>:not(:last-child):not(:first-child)': {
              borderRight: '1px solid #C4C4C4',
              borderLeft: '1px solid #C4C4C4',
            },
          }"
          as="tr"
          border-bottom="1px solid #C4C4C4"
        >
          <c-box as="td" />
          <c-box
            v-for="item in data?.comparation?.[tabActive]?.productServices || []"
            :key="`buy-${item?.id}`"
            as="td"
            px="1rem"
            py="1rem"
          >
            <slot
              name="button"
              :productService="item /* eslint-disable-line vue/attribute-hyphenation */ "
            />
            <!--            <c-button-->
            <!--              v-chakra="{-->
            <!--                ':hover': {-->
            <!--                  color:'primary.500',-->
            <!--                  background:'secondary.500',-->
            <!--                },-->
            <!--                ':active': {-->
            <!--                  color: 'white',-->
            <!--                  background: 'secondary.600',-->
            <!--                },-->
            <!--              }"-->
            <!--              width="100%"-->
            <!--              variant="solid"-->
            <!--              color="primary.400"-->
            <!--              background="secondary.400"-->
            <!--              border-radius="60px"-->
            <!--              height="50px"-->
            <!--            >-->
            <!--              &lt;!&ndash;            @click.prevent="onClickBuy(item?.id)"&ndash;&gt;-->
            <!--              Beli Sekarang-->
            <!--            </c-button>-->
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <c-box
      pos="relative"
      :display="['block', 'none']"
      z-index="3"
      width="100%"
    >
      <c-grid
        v-chakra="{
          '> :not(:last-child):not(:first-child) > div': {
            borderLeft:'1px solid #005A64',
            borderRight:'1px solid #005A64',
          },
        }"
        w="100%"
        :template-columns="`repeat(${data?.comparation?.[tabActive]?.productServices?.length || 0}, 1fr)`"
        background-color="#00A68C"
        color="#FFF"
        font-size="14px"
        font-weight="500"
        border="1px solid #C4C4C4"
      >
        <c-box
          v-for="(item, itemIdx) in data?.comparation?.[tabActive]?.productServices || []"
          :key="item?.programsService"
          w="100%"
          py="6px"
        >
          <c-flex
            w="100%"
            flex-direction="column"
            align-items="center"
            :border-right="itemIdx === 0 ? '1px solid #005A64' : 'unset'"
          >
            <c-text mb="8px">
              {{ item?.programsService }}
            </c-text>
            <c-text
              font-size="20px"
            >
              {{ formatCurrency(item?.price, 2) }}
            </c-text>
            <c-text font-size="10px">
              ({{ formatCurrency(Math.floor(item?.price / item?.duration), 2) }}/hari)
            </c-text>
          </c-flex>
        </c-box>
      </c-grid>
      <c-box
        p="16px 8px 8px 8px"
        color="#000"
        font-size="12px"
        font-weight="400"
        background-color="#FFF"
        border-left="1px solid #C4C4C4"
        border-right="1px solid #C4C4C4"
        border-bottom="1px solid #C4C4C4"
      >
        <c-box
          v-for="(facility, index) in data.facilities"
          :key="`facility-${index}`"
          mb="8px"
        >
          <c-box
            p="6px 12px"
            background-color="primary.50"
            text-align="center"
          >
            {{ facility?.facility }}
          </c-box>
          <c-grid
            v-chakra="{
              '> :not(:last-child):not(:first-child)': {
                borderLeft:'1px solid #C4C4C4',
                borderRight:'1px solid #C4C4C4',
              },
            }"
            w="100%"
            :template-columns="`repeat(${data?.comparation?.[tabActive]?.productServices?.length || 0}, 1fr)`"
          >
            <c-flex
              v-for="(j, jIdx) in data?.comparation?.[tabActive]?.productServices?.length || 0"
              :key="j"
              w="100%"
              py="4px"
              flex-direction="column"
              align-items="center"
              :border-right="jIdx === 0 ? '1px solid #C4C4C4' : 'unset'"
            >
              <inline-svg
                v-if="isCheckList(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices?.[j-1]?.productFacilitiesComparation))"
                :src="checkIcon(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices?.[j-1]?.productFacilitiesComparation)) ? require('@/assets/icons/icon-circle-check.svg') : require('@/assets/icons/icon-circle-close.svg')"
                class="icon-list"
                :fill="checkIcon(getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices?.[j-1]?.productFacilitiesComparation)) ? '#008C81' : '#D32737'"
              />
              <c-box
                v-else
              >
                {{ getFacilityValue(facility?.facility, data?.comparation?.[tabActive]?.productServices?.[j-1]?.productFacilitiesComparation) }}
              </c-box>
            </c-flex>
          </c-grid>
        </c-box>
        <c-grid
          v-chakra="{
            '> :not(:last-child):not(:first-child)': {
              borderLeft:'1px solid #C4C4C4',
              borderRight:'1px solid #C4C4C4',
            },
          }"
          w="100%"
          :template-columns="`repeat(${data?.comparation?.[tabActive]?.productServices?.length || 0}, 1fr)`"
        >
          <c-box
            v-for="item in data?.comparation?.[tabActive]?.productServices ||[]"
            :key="item.id"
            p="6px"
          >
            <slot
              name="button"
              :productService="item /* eslint-disable-line vue/attribute-hyphenation */ "
            />
          </c-box>
        </c-grid>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { CBox, CFlex, CGrid, CText } from '@chakra-ui/vue'
import { formatCurrency } from '@/utils/format-currency'
import anime from 'animejs/lib/anime.es.js'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { reqProgram } from '@/requests/dietela-api/general/program'

export default {
  name: 'TableProductComparation',
  components: {
    CFlex,
    CGrid,
    CBox,
    CText,
  },
  mixins: [mixinsCheckBreakpoints()],
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {},
      tabActive: 0,
    }
  },
  watch: {
    currentBreakpoint() { // change height of tab when breakpoint change
      this.changeTab(this.tabActive)
    },
    $route: {
      handler() {
        this.init()
        this.changeTab(0)
      },
      immediate: true,
    },
  },
  methods: {
    async init() {
      if (!this.productId) return
      const res = await reqProgram.comparation(this.$store.getters.axios, {
        productId: this.productId,
      })
      this.data = res?.data?.data
    },
    formatCurrency,
    changeTab(tab) {
      this.tabActive = tab
      const isSmallScreen = ['sm', 'md'].includes(this.currentBreakpoint)
      const heights = {
        true: [30, 41],
        false: [60, 70],
      }
      const tab1 = anime({
        targets: '.tab-0',
        height: tab === 0 ? heights[isSmallScreen][1] : heights[isSmallScreen][0],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      const tab2 = anime({
        targets: '.tab-1',
        height: tab === 1 ? heights[isSmallScreen][1] : heights[isSmallScreen][0],
        duration: 500,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)',
      })
      tab1.play()
      tab2.play()
    },
    getFacilityValue(label, data) {
      return data?.find(it => it?.productFacilities?.facility === label)?.value
    },
    isCheckList(value) {
      if (value && value?.includes('[x]') || value?.includes('[v]')) {
        return 1
      }
      return 0
    },
    checkIcon(value) {
      if (value && value?.includes('[x]')) {
        return 0
      }
      return 1
    },
  },
}
</script>

<style scoped>
.table-consultation-2 {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  overflow: hidden;
}
.icon-list {
  width: 17px;
  height: 17px;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .icon-list {
    width: 20px;
    height: 20px;
  }
}
</style>