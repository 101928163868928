var render = function () {
  var _vm$data, _vm$data$comparation, _vm$data2, _vm$data2$facilities, _vm$data3, _vm$data3$comparation, _vm$data3$comparation2, _vm$data4, _vm$data10, _vm$data10$comparatio, _vm$data10$comparatio2, _vm$data11, _vm$data11$comparatio, _vm$data11$comparatio2, _vm$data11$comparatio3, _vm$data12, _vm$data12$comparatio, _vm$data12$comparatio2, _vm$data19, _vm$data19$comparatio, _vm$data19$comparatio2, _vm$data19$comparatio3, _vm$data20, _vm$data20$comparatio, _vm$data20$comparatio2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return ((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$comparation = _vm$data.comparation) === null || _vm$data$comparation === void 0 ? void 0 : _vm$data$comparation.length) > 0 && ((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$facilities = _vm$data2.facilities) === null || _vm$data2$facilities === void 0 ? void 0 : _vm$data2$facilities.length) > 0 ? _c('c-box', [_c('c-flex', {
    attrs: {
      "min-h": ['41px', '70px'],
      "align-items": "flex-end"
    }
  }, [_c('c-flex', {
    staticClass: "tab-0",
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "width": "100%",
      "height": ['41px', '70px'],
      "background-color": _vm.tabActive === 0 ? 'primary.400' : '#C7F9E3',
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "cursor": "pointer",
      "user-select": "none",
      "_hover": {
        filter: 'brightness(0.95)'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.changeTab(0);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.tabActive === 0 ? 'white' : 'primary.400',
      "size-mobile": "14px-3",
      "size-desktop": "18px",
      "width": "100%",
      "text-align": "center",
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" Paket 30 hari ")])], 1), _c('c-flex', {
    staticClass: "tab-1",
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "width": "100%",
      "height": ['30px', '60px'],
      "background-color": _vm.tabActive === 1 ? 'primary.400' : '#C7F9E3',
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "cursor": "pointer",
      "user-select": "none",
      "_hover": {
        filter: 'brightness(0.95)'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.changeTab(1);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "color": _vm.tabActive === 1 ? 'white' : 'primary.400',
      "size-mobile": "14px-3",
      "size-desktop": "18px",
      "width": "100%",
      "text-align": "center",
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" Paket 90 hari ")])], 1)], 1), _c('c-box', {
    staticClass: "table-consultation-2",
    attrs: {
      "pos": "relative",
      "display": ['none', 'table'],
      "as": "table",
      "width": "100%",
      "z-index": "3"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "thead",
      "color": "#FFF",
      "background-color": "#00A68C",
      "font-weight": "700"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:first-child': {
          borderLeft: '1px solid #005A64'
        },
        '>:last-child': {
          borderRight: '1px solid #005A64'
        },
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #005A64',
          borderLeft: '1px solid #005A64'
        }
      },
      expression: "{\n          '>:first-child': {\n            borderLeft: '1px solid #005A64',\n          },\n          '>:last-child': {\n            borderRight: '1px solid #005A64',\n          },\n          '>:not(:last-child):not(:first-child)': {\n            borderRight: '1px solid #005A64',\n            borderLeft: '1px solid #005A64',\n          },\n        }"
    }],
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "text-align": "center",
      "font-size": "24px",
      "py": "1rem"
    }
  }, [_vm._v(" Fasilitas Layanan ")]), _vm._l(((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$comparation = _vm$data3.comparation) === null || _vm$data3$comparation === void 0 ? void 0 : (_vm$data3$comparation2 = _vm$data3$comparation[_vm.tabActive]) === null || _vm$data3$comparation2 === void 0 ? void 0 : _vm$data3$comparation2.productServices) || [], function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "as": "th",
        "flex-direction": "column",
        "justify": "center",
        "py": "1rem"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "20px",
        "margin-bottom": "8px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "32px"
      }
    }, [_c('c-text', {
      attrs: {
        "as": "span",
        "font-size": "16px",
        "font-weight": "400"
      }
    }, [_vm._v(" Mulai dari Rp ")]), _vm._v(" " + _vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price, 2).replace('Rp. ', '')) + " ")], 1), _c('c-text', {
      attrs: {
        "as": "span",
        "font-size": "16px",
        "font-weight": "400"
      }
    }, [_vm._v(" Per Bulan (" + _vm._s(_vm.formatCurrency(Math.floor((item === null || item === void 0 ? void 0 : item.price) / (item === null || item === void 0 ? void 0 : item.duration)), 2)) + "/hari) ")])], 1);
  })], 2)], 1), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> tr': {
          borderLeft: '1px solid #C4C4C4',
          borderRight: '1px solid #C4C4C4'
        }
      },
      expression: "{\n        '> tr': {\n          borderLeft: '1px solid #C4C4C4',\n          borderRight: '1px solid #C4C4C4',\n        },\n      }"
    }],
    attrs: {
      "as": "tbody",
      "background-color": "#FFF",
      "color": "#000",
      "font-size": ['12px', '16px']
    }
  }, [_vm._l(((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.facilities) || [], function (facility, i) {
    var _vm$data5, _vm$data5$comparation, _vm$data5$comparation2, _vm$data5$comparation3;

    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '>:not(:last-child):not(:first-child)': {
            borderRight: '1px solid #C4C4C4',
            borderLeft: '1px solid #C4C4C4'
          }
        },
        expression: "{\n          '>:not(:last-child):not(:first-child)': {\n            borderRight: '1px solid #C4C4C4',\n            borderLeft: '1px solid #C4C4C4',\n          },\n        }"
      }],
      key: "tr-".concat(i),
      attrs: {
        "as": "tr"
      }
    }, _vm._l((((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$comparation = _vm$data5.comparation) === null || _vm$data5$comparation === void 0 ? void 0 : (_vm$data5$comparation2 = _vm$data5$comparation[_vm.tabActive]) === null || _vm$data5$comparation2 === void 0 ? void 0 : (_vm$data5$comparation3 = _vm$data5$comparation2.productServices) === null || _vm$data5$comparation3 === void 0 ? void 0 : _vm$data5$comparation3.length) || 0) + 1, function (k) {
      var _vm$data6, _vm$data6$comparation, _vm$data6$comparation2, _vm$data6$comparation3, _vm$data7, _vm$data7$comparation, _vm$data7$comparation2, _vm$data7$comparation3, _vm$data8, _vm$data8$comparation, _vm$data8$comparation2, _vm$data8$comparation3, _vm$data9, _vm$data9$comparation, _vm$data9$comparation2, _vm$data9$comparation3;

      return _c('c-box', {
        key: "td-".concat(i, "-").concat(k),
        attrs: {
          "as": "td",
          "px": "1rem",
          "text-align": k === 1 ? 'left' : 'center'
        }
      }, [k === 1 ? _c('c-box', {
        attrs: {
          "w": "100%",
          "pt": i === 0 ? '2rem' : '1rem',
          "pb": "1rem",
          "px": k === 1 ? '12px' : '0',
          "border-bottom": "1px solid #C4C4C4"
        }
      }, [_vm._v(" " + _vm._s(facility === null || facility === void 0 ? void 0 : facility.facility) + " ")]) : _c('c-box', {
        attrs: {
          "w": "100%",
          "pt": i === 0 ? '2rem' : '1rem',
          "pb": "1rem",
          "border-bottom": "1px solid #C4C4C4",
          "display": "flex",
          "justify-content": "center"
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$comparation = _vm$data6.comparation) === null || _vm$data6$comparation === void 0 ? void 0 : (_vm$data6$comparation2 = _vm$data6$comparation[_vm.tabActive]) === null || _vm$data6$comparation2 === void 0 ? void 0 : (_vm$data6$comparation3 = _vm$data6$comparation2.productServices[k - 2]) === null || _vm$data6$comparation3 === void 0 ? void 0 : _vm$data6$comparation3.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$comparation = _vm$data7.comparation) === null || _vm$data7$comparation === void 0 ? void 0 : (_vm$data7$comparation2 = _vm$data7$comparation[_vm.tabActive]) === null || _vm$data7$comparation2 === void 0 ? void 0 : (_vm$data7$comparation3 = _vm$data7$comparation2.productServices[k - 2]) === null || _vm$data7$comparation3 === void 0 ? void 0 : _vm$data7$comparation3.productFacilitiesComparation)) ? require('@/assets/icons/icon-circle-check.svg') : require('@/assets/icons/icon-circle-close.svg'),
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$comparation = _vm$data8.comparation) === null || _vm$data8$comparation === void 0 ? void 0 : (_vm$data8$comparation2 = _vm$data8$comparation[_vm.tabActive]) === null || _vm$data8$comparation2 === void 0 ? void 0 : (_vm$data8$comparation3 = _vm$data8$comparation2.productServices[k - 2]) === null || _vm$data8$comparation3 === void 0 ? void 0 : _vm$data8$comparation3.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$comparation = _vm$data9.comparation) === null || _vm$data9$comparation === void 0 ? void 0 : (_vm$data9$comparation2 = _vm$data9$comparation[_vm.tabActive]) === null || _vm$data9$comparation2 === void 0 ? void 0 : (_vm$data9$comparation3 = _vm$data9$comparation2.productServices[k - 2]) === null || _vm$data9$comparation3 === void 0 ? void 0 : _vm$data9$comparation3.productFacilitiesComparation)) + " ")])], 1)], 1);
    }), 1);
  }), _c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '>:not(:last-child):not(:first-child)': {
          borderRight: '1px solid #C4C4C4',
          borderLeft: '1px solid #C4C4C4'
        }
      },
      expression: "{\n          '>:not(:last-child):not(:first-child)': {\n            borderRight: '1px solid #C4C4C4',\n            borderLeft: '1px solid #C4C4C4',\n          },\n        }"
    }],
    attrs: {
      "as": "tr",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td"
    }
  }), _vm._l(((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$comparatio = _vm$data10.comparation) === null || _vm$data10$comparatio === void 0 ? void 0 : (_vm$data10$comparatio2 = _vm$data10$comparatio[_vm.tabActive]) === null || _vm$data10$comparatio2 === void 0 ? void 0 : _vm$data10$comparatio2.productServices) || [], function (item) {
    return _c('c-box', {
      key: "buy-".concat(item === null || item === void 0 ? void 0 : item.id),
      attrs: {
        "as": "td",
        "px": "1rem",
        "py": "1rem"
      }
    }, [_vm._t("button", null, {
      "productService": item
      /* eslint-disable-line vue/attribute-hyphenation */

    })], 2);
  })], 2)], 2)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "display": ['block', 'none'],
      "z-index": "3",
      "width": "100%"
    }
  }, [_c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:last-child):not(:first-child) > div': {
          borderLeft: '1px solid #005A64',
          borderRight: '1px solid #005A64'
        }
      },
      expression: "{\n        '> :not(:last-child):not(:first-child) > div': {\n          borderLeft:'1px solid #005A64',\n          borderRight:'1px solid #005A64',\n        },\n      }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : (_vm$data11$comparatio = _vm$data11.comparation) === null || _vm$data11$comparatio === void 0 ? void 0 : (_vm$data11$comparatio2 = _vm$data11$comparatio[_vm.tabActive]) === null || _vm$data11$comparatio2 === void 0 ? void 0 : (_vm$data11$comparatio3 = _vm$data11$comparatio2.productServices) === null || _vm$data11$comparatio3 === void 0 ? void 0 : _vm$data11$comparatio3.length) || 0, ", 1fr)"),
      "background-color": "#00A68C",
      "color": "#FFF",
      "font-size": "14px",
      "font-weight": "500",
      "border": "1px solid #C4C4C4"
    }
  }, _vm._l(((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$comparatio = _vm$data12.comparation) === null || _vm$data12$comparatio === void 0 ? void 0 : (_vm$data12$comparatio2 = _vm$data12$comparatio[_vm.tabActive]) === null || _vm$data12$comparatio2 === void 0 ? void 0 : _vm$data12$comparatio2.productServices) || [], function (item, itemIdx) {
    return _c('c-box', {
      key: item === null || item === void 0 ? void 0 : item.programsService,
      attrs: {
        "w": "100%",
        "py": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "flex-direction": "column",
        "align-items": "center",
        "border-right": itemIdx === 0 ? '1px solid #005A64' : 'unset'
      }
    }, [_c('c-text', {
      attrs: {
        "mb": "8px"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.programsService) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item === null || item === void 0 ? void 0 : item.price, 2)) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "10px"
      }
    }, [_vm._v(" (" + _vm._s(_vm.formatCurrency(Math.floor((item === null || item === void 0 ? void 0 : item.price) / (item === null || item === void 0 ? void 0 : item.duration)), 2)) + "/hari) ")])], 1)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "p": "16px 8px 8px 8px",
      "color": "#000",
      "font-size": "12px",
      "font-weight": "400",
      "background-color": "#FFF",
      "border-left": "1px solid #C4C4C4",
      "border-right": "1px solid #C4C4C4",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_vm._l(_vm.data.facilities, function (facility, index) {
    var _vm$data13, _vm$data13$comparatio, _vm$data13$comparatio2, _vm$data13$comparatio3, _vm$data14, _vm$data14$comparatio, _vm$data14$comparatio2, _vm$data14$comparatio3;

    return _c('c-box', {
      key: "facility-".concat(index),
      attrs: {
        "mb": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "p": "6px 12px",
        "background-color": "primary.50",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(facility === null || facility === void 0 ? void 0 : facility.facility) + " ")]), _c('c-grid', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '> :not(:last-child):not(:first-child)': {
            borderLeft: '1px solid #C4C4C4',
            borderRight: '1px solid #C4C4C4'
          }
        },
        expression: "{\n            '> :not(:last-child):not(:first-child)': {\n              borderLeft:'1px solid #C4C4C4',\n              borderRight:'1px solid #C4C4C4',\n            },\n          }"
      }],
      attrs: {
        "w": "100%",
        "template-columns": "repeat(".concat(((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : (_vm$data13$comparatio = _vm$data13.comparation) === null || _vm$data13$comparatio === void 0 ? void 0 : (_vm$data13$comparatio2 = _vm$data13$comparatio[_vm.tabActive]) === null || _vm$data13$comparatio2 === void 0 ? void 0 : (_vm$data13$comparatio3 = _vm$data13$comparatio2.productServices) === null || _vm$data13$comparatio3 === void 0 ? void 0 : _vm$data13$comparatio3.length) || 0, ", 1fr)")
      }
    }, _vm._l(((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : (_vm$data14$comparatio = _vm$data14.comparation) === null || _vm$data14$comparatio === void 0 ? void 0 : (_vm$data14$comparatio2 = _vm$data14$comparatio[_vm.tabActive]) === null || _vm$data14$comparatio2 === void 0 ? void 0 : (_vm$data14$comparatio3 = _vm$data14$comparatio2.productServices) === null || _vm$data14$comparatio3 === void 0 ? void 0 : _vm$data14$comparatio3.length) || 0, function (j, jIdx) {
      var _vm$data15, _vm$data15$comparatio, _vm$data15$comparatio2, _vm$data15$comparatio3, _vm$data15$comparatio4, _vm$data16, _vm$data16$comparatio, _vm$data16$comparatio2, _vm$data16$comparatio3, _vm$data16$comparatio4, _vm$data17, _vm$data17$comparatio, _vm$data17$comparatio2, _vm$data17$comparatio3, _vm$data17$comparatio4, _vm$data18, _vm$data18$comparatio, _vm$data18$comparatio2, _vm$data18$comparatio3, _vm$data18$comparatio4;

      return _c('c-flex', {
        key: j,
        attrs: {
          "w": "100%",
          "py": "4px",
          "flex-direction": "column",
          "align-items": "center",
          "border-right": jIdx === 0 ? '1px solid #C4C4C4' : 'unset'
        }
      }, [_vm.isCheckList(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$comparatio = _vm$data15.comparation) === null || _vm$data15$comparatio === void 0 ? void 0 : (_vm$data15$comparatio2 = _vm$data15$comparatio[_vm.tabActive]) === null || _vm$data15$comparatio2 === void 0 ? void 0 : (_vm$data15$comparatio3 = _vm$data15$comparatio2.productServices) === null || _vm$data15$comparatio3 === void 0 ? void 0 : (_vm$data15$comparatio4 = _vm$data15$comparatio3[j - 1]) === null || _vm$data15$comparatio4 === void 0 ? void 0 : _vm$data15$comparatio4.productFacilitiesComparation)) ? _c('inline-svg', {
        staticClass: "icon-list",
        attrs: {
          "src": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : (_vm$data16$comparatio = _vm$data16.comparation) === null || _vm$data16$comparatio === void 0 ? void 0 : (_vm$data16$comparatio2 = _vm$data16$comparatio[_vm.tabActive]) === null || _vm$data16$comparatio2 === void 0 ? void 0 : (_vm$data16$comparatio3 = _vm$data16$comparatio2.productServices) === null || _vm$data16$comparatio3 === void 0 ? void 0 : (_vm$data16$comparatio4 = _vm$data16$comparatio3[j - 1]) === null || _vm$data16$comparatio4 === void 0 ? void 0 : _vm$data16$comparatio4.productFacilitiesComparation)) ? require('@/assets/icons/icon-circle-check.svg') : require('@/assets/icons/icon-circle-close.svg'),
          "fill": _vm.checkIcon(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : (_vm$data17$comparatio = _vm$data17.comparation) === null || _vm$data17$comparatio === void 0 ? void 0 : (_vm$data17$comparatio2 = _vm$data17$comparatio[_vm.tabActive]) === null || _vm$data17$comparatio2 === void 0 ? void 0 : (_vm$data17$comparatio3 = _vm$data17$comparatio2.productServices) === null || _vm$data17$comparatio3 === void 0 ? void 0 : (_vm$data17$comparatio4 = _vm$data17$comparatio3[j - 1]) === null || _vm$data17$comparatio4 === void 0 ? void 0 : _vm$data17$comparatio4.productFacilitiesComparation)) ? '#008C81' : '#D32737'
        }
      }) : _c('c-box', [_vm._v(" " + _vm._s(_vm.getFacilityValue(facility === null || facility === void 0 ? void 0 : facility.facility, (_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : (_vm$data18$comparatio = _vm$data18.comparation) === null || _vm$data18$comparatio === void 0 ? void 0 : (_vm$data18$comparatio2 = _vm$data18$comparatio[_vm.tabActive]) === null || _vm$data18$comparatio2 === void 0 ? void 0 : (_vm$data18$comparatio3 = _vm$data18$comparatio2.productServices) === null || _vm$data18$comparatio3 === void 0 ? void 0 : (_vm$data18$comparatio4 = _vm$data18$comparatio3[j - 1]) === null || _vm$data18$comparatio4 === void 0 ? void 0 : _vm$data18$comparatio4.productFacilitiesComparation)) + " ")])], 1);
    }), 1)], 1);
  }), _c('c-grid', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '> :not(:last-child):not(:first-child)': {
          borderLeft: '1px solid #C4C4C4',
          borderRight: '1px solid #C4C4C4'
        }
      },
      expression: "{\n          '> :not(:last-child):not(:first-child)': {\n            borderLeft:'1px solid #C4C4C4',\n            borderRight:'1px solid #C4C4C4',\n          },\n        }"
    }],
    attrs: {
      "w": "100%",
      "template-columns": "repeat(".concat(((_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : (_vm$data19$comparatio = _vm$data19.comparation) === null || _vm$data19$comparatio === void 0 ? void 0 : (_vm$data19$comparatio2 = _vm$data19$comparatio[_vm.tabActive]) === null || _vm$data19$comparatio2 === void 0 ? void 0 : (_vm$data19$comparatio3 = _vm$data19$comparatio2.productServices) === null || _vm$data19$comparatio3 === void 0 ? void 0 : _vm$data19$comparatio3.length) || 0, ", 1fr)")
    }
  }, _vm._l(((_vm$data20 = _vm.data) === null || _vm$data20 === void 0 ? void 0 : (_vm$data20$comparatio = _vm$data20.comparation) === null || _vm$data20$comparatio === void 0 ? void 0 : (_vm$data20$comparatio2 = _vm$data20$comparatio[_vm.tabActive]) === null || _vm$data20$comparatio2 === void 0 ? void 0 : _vm$data20$comparatio2.productServices) || [], function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "p": "6px"
      }
    }, [_vm._t("button", null, {
      "productService": item
      /* eslint-disable-line vue/attribute-hyphenation */

    })], 2);
  }), 1)], 2)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }